
import HF from '../assets/highfive.png'
import BAGGY from '../assets/BAGGY ILAY - REV01.jpg'
import GEMONG from '../assets/GEMONG TOLENTINO-REV01.jpg'
import GILLEA from '../assets/GILEA ANN ORUGA - REV01.jpg'
import JERRY from '../assets/JERRY DICHOSO - REV01.jpg'
import JOSE from '../assets/JOSE SAN PEDRO - REV01.jpg'
import TIM from '../assets/MATTHEW TIM RIZAL - REV01.jpg'
import RAFAEL from '../assets/RAFAEL BERNABE - REV01.jpg'
import SANDY from '../assets/SANDY GELI - REV01.jpg'

import LOGOPALO from '../assets/logopalo.png'
import JOVI from '../assets/JOVI AND REX.jpg'
import REX from '../assets/SK-CHAIRMAN-REX-MANGUIAT.jpg'
import PLT1 from '../assets/plt1.jpg'
import PLT2 from '../assets/plt2.jpg'
import PLT3 from '../assets/plt3.jpg'
import PLT4 from '../assets/plt4.jpg'
import PLT5 from '../assets/plt5.jpg'
import PLT6 from '../assets/plt6.jpg'
import PLT7 from '../assets/plt7.jpg'

import GEN from '../assets/SerbisyongGenerSuelto.gif'
import GENX from '../assets/ccccc.png'

import ED from '../assets/EDWIN MEDINA.jpg'

export const BRGY_CONFIG = {
    'PLT': {
        brgyName: 'Brgy. PALO-ALTO',
        db: 'palo-alto',
        googleFormLink: 'https://docs.google.com/forms/d/e/1FAIpQLSdrq1KmaRUcGTmoJT464UFzOZtYxadkCFER1Ro0sCeWF_I64w/viewform?embedded=true',
        googleFormWidth: '400',
        googleFormHeight: '1800',
        headerColor: '#FF302E',
        headerLogo: LOGOPALO,
        mainCard: JOVI,
        cardImages: [PLT1, PLT2, PLT3, PLT4, PLT5, PLT6, PLT7],
        saveNameIP: '1371499565',
        strictSearch: false
    },
    'CNC': {
        brgyName: 'Brgy. 5',
        db: 'brgy5',
        googleFormLink: 'https://docs.google.com/forms/d/e/1FAIpQLSfQcknYKFXycks8C2ul4XzwCA4d5kiSZ437UlFjY5wZgbU3lw/viewform?embedded=true',
        googleFormWidth: '400',
        googleFormHeight: '1000',
        headerColor: '#003248',
        headerLogo: HF,
        mainCard: TIM,
        cardImages: [RAFAEL, JERRY, SANDY, BAGGY, GILLEA, JOSE, GEMONG],
        saveNameIP: '1860499358',
        strictSearch: true
    },
    'LCH': {
        brgyName: 'Brgy. Lecheria',
        db: 'lecheria',
        googleFormLink: 'https://docs.google.com/forms/d/e/1FAIpQLSfYC1XoMTjr3tni1Pv0psp7Qcb_excl6-12ZNyfD-6Vq_RJ1g/viewform?embedded=true',
        googleFormWidth: '400',
        googleFormHeight: '1800',
        headerColor: '#2AB7FF',
        headerLogo: GENX,
        mainCard: GEN,
        cardImages: [],
        saveNameIP: '',
        strictSearch: false
    },
    'CNX': {
        brgyName: 'Brgy. 5',
        db: 'brgy5',
        googleFormLink: '',
        googleFormWidth: '',
        googleFormHeight: '',
        headerColor: '#2AB7FF',
        headerLogo: '',
        mainCard: ED,
        cardImages: [],
        saveNameIP: '',
        strictSearch: true
    },
}