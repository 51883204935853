
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: {
        first: '',
        last: '',
        result: {
            name: '' ,
            precinct: '',
            cluster: ''
        }
    }
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setData: (state, action) => ({ ...state, data: action.payload }),
        // setVotes: (state, action) => ({ ...state, summary: action.payload })
    }
})

export const getApp = (state) => state.app;
export default appSlice;
