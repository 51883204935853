import firebase from "firebase";
import "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyBQCEGuS_kKqLsT-OKHCuDCfYvrt_t4d7k",
  authDomain: "vto-rto-app.firebaseapp.com",
  databaseURL: "https://vto-rto-app-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "vto-rto-app",
  storageBucket: "vto-rto-app.appspot.com",
  messagingSenderId: "581282350439",
  appId: "1:581282350439:web:2938d11fb1f10cc7e25bc2"
};

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore

export default firebase;